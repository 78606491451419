
























































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListUser from '@/components/AppListUser.vue';
import AppListPayRate from '@/components/AppListPayRate.vue';
import USERS from '@/graphql/queries/Users.graphql';
import USER_ROLE from '@/graphql/queries/UserRole.graphql';
import USER_DELETE from '@/graphql/mutations/UserDelete.graphql';
import { USER_ROLES } from '@/utils/constants';
import { User } from '@/types/schema';

export default Vue.extend({
  name: 'AdminUsers',
  components: {
    AppMain,
    AppEmpty,
    AppPanel,
    AppPanelItem,
    AppListUser,
    AppListPayRate,
  },
  data() {
    return {
      users: [] as User[],
      currentUser: {} as User,
    };
  },
  methods: {
    isCurrentUser(id: User['id']) {
      return this.currentUser.id === id;
    },
    canSetPayRate(role: string): boolean {
      return role !== USER_ROLES.COOP;
    },
    async deleteUser(id: User['id']) {
      if (!id || this.isCurrentUser(id)) return;
      if (!confirm("Are you sure? This action can't be undone.")) return;

      try {
        await this.$apollo.mutate({
          mutation: USER_DELETE,
          variables: {
            input: { id },
          },
        });
        this.$apollo.queries.users.refetch();
        this.$notify({
          text: 'User deleted!',
          type: 'success',
          duration: 6000,
        });
      } catch (error) {
        this.$notify({
          text: error.graphQLErrors[0].message,
          type: 'error',
          duration: 6000,
        });
      }
    },
  },
  apollo: {
    users: {
      query: USERS,
      fetchPolicy: 'cache-and-network',
    },
    currentUser: {
      query: USER_ROLE,
      update: (data) => data.user,
    },
  },
});
