






































import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import AppListItemAddress from '@/components/AppListItemAddress.vue';
import formatDate from '@/utils/formatDate';
import { User } from '@/types/schema';

export default Vue.extend({
  name: 'AppListUser',
  components: {
    AppListItem,
    AppListItemAddress,
  },
  inheritAttrs: false,
  props: {
    user: {
      type: Object as () => User,
      default: Object as () => User,
    },
  },
  methods: {
    formatDate,
  },
});
