




































import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import formatCurrency from '@/utils/formatCurrency';
import { PayRate } from '@/types/schema';

export default Vue.extend({
  name: 'AppListPayRate',
  components: {
    AppListItem,
  },
  inheritAttrs: false,
  props: {
    rate: {
      type: Object as () => PayRate,
      default: Object as () => PayRate,
    },
  },
  methods: {
    formatCurrency,
  },
});
